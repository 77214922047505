import * as SecureStorage from 'secure-web-storage';
import CONST from '../common/constants';

var CryptoJS = require("crypto-js");
var Placeholder = CONST.PLACEHOLDER_TEXT;

var secureStorage = new SecureStorage(localStorage, {
    hash: function hash(key) {
        key = CryptoJS.SHA256(key, Placeholder);

        return key.toString();
    },
    encrypt: function encrypt(data) {
        data = CryptoJS.AES.encrypt(data, Placeholder);

        data = data.toString();

        return data;
    },
    decrypt: function decrypt(data) {
        data = CryptoJS.AES.decrypt(data, Placeholder);

        data = data.toString(CryptoJS.enc.Utf8);

        return data;
    }
});

export default secureStorage;