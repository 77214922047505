// import { encrypt, decrypt } from 'crypto.service';
// import moment from 'moment';
import secureStorage from './securedStorage';
import Amplify, { Auth, Hub } from 'aws-amplify';
import AWS from 'aws-sdk';
import Const from '../common/constants';
import * as AuthAPI from '../api/auth.api'
// var cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();

export async function logout() {
    let response = await Auth.currentSession(); //1. Make a synchronous API call to refresh token
    let refreshToken = response.getRefreshToken().getToken();
    var payload = {
        ClientId: Const.AWS.StagingPool.WebClientID,
        /* required */
        Token: refreshToken /* required */
    };
    // cognitoidentityserviceprovider.revokeToken(params, function(err, data) {
    //     if (err) console.log(err, err.stack); // an error occurred
    //     else console.log(data); // successful response
    // });
    Auth.signOut().then(() => {
        let persistData = secureStorage.getItem("subscriptionRedirect");
        window.localStorage.clear();
        if ((persistData !== undefined && persistData !== null) && window.screen.width < 991) {
            secureStorage.setItem("subscriptionRedirect", true);
        }

        window.location.href = '/auth/login';
        AuthAPI.revokeRefreshToken(payload);
    }).catch((err) => {
        console.log(err);
        Auth.signOut();
    });
}