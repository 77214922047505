import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
    // we init with resources
    resources: {
        en: {
            translations: {
                string_invalid_pwd: 'Password entered is incorrect. Please try again',
                string_pwd_does_not_match: 'Passwords does not match',
                string_invalid_email: 'Please enter a valid Email ID',
                string_invalid_phone: 'Please enter a valid number',
                string_invalid_otp: 'Please enter a valid OTP',
                string_invalid_name: 'Please enter a valid name',
                string_user_unauthorized: 'The mobile number and password entered do not match',
                string_user_already_onboarded: 'User already exists. Please login',
                string_no_internet: 'There is no Internet Connection',
                explainer_message_1: 'Just a few steps to secure your whole world',
                explainer_message_2: 'Best in-class data security | Data stays in India',
                explainer_message_3: 'Choose between storing recordings on SD Card or Cloud',
                login_head_text: 'Login for a great experience',
                signup_text_first: 'New around here?',
                signup_text_second: 'Sign up to enter a secure world',
                privacy_text: 'By continuing, you agree to',
                signup_intro_text: 'Sign up to secure your world',
                password_reset_text: 'Please enter your registered mobile number to reset your password',
                min_char_rule: 'Minimum 08 characters',
                capital_letter_rule: '1 capital letter',
                number_rule: '1 alpha numeric',
                special_char_rule: '1 special character',
                congratulation_message: 'Congratulations ! Your account has been successfully created. Login to continue',
                login_text: 'Already have an account? Log in',
                attempts_exceeded_message: 'Multiple failed login attempts. Please try again later',
                profile_request_message: 'Please upload your profile picture to complete the signup process',
                welcome_text: 'Welcome Home',
                add_camera_text: "Let's start by adding your device",
                Before_we_start: 'A few things to keep in mind before we start',
                stable_wifi_message: 'Make sure you have a stable WiFi and internet connection',
                camera_wifi_requirement_1: 'Ensure mobile is connected to the same WiFi you wish to use with your Spotlight Camera',
                camera_wifi_requirement_2: 'Maintain a distance of 30cm or more between your mobile and Godrej Spotlight camera',
                no_wifi_network: "You don't seem to be connected to a WiFi network. Please check and try again",
                start_viewing: 'Start viewing live feed',
                timeout_message: 'Sorry, this is taking unusually long. Please try again later',
                share_camera_limit_outofbound: 'Sorry! You have exceeded the timelimit (3:00PM to 6:00PM) for shared camera',
                user_limit_exceeded: 'Oops! Multiple sessions in-progress. Please try again later',
                sleep_mode_message: 'Shhh! Live view disabled. CameraName is in sleep mode. Disable Sleep mode from settings to enable live view again',
                sdcard_initialize_message: 'SD card initializing… it should take 90 seconds or less. Thanks for waiting!',
                sdcard_offline_mode_message: 'Sorry, no show! SD card playback is disabled in offline mode.',
                finished_playing_recording: "That's a wrap for today! Finished playing all recordings",
                select_device_warning: 'You must select at least one device to continue',
                end_date_warning: 'End date cannot be less than start date',
                end_time_warning: 'End time cannot be less than start time',
                weekday_warning: 'Select at least one weekday',
                mandatory_field_warning: 'This field is mandatory',
                share_link_message: 'Would you like to share the link with others?',
                delete_share_message: 'Are you sure you want to delete this configuration ?',
                DND_message: 'Enabling Do Not Disturb will mute all push notifications. You can still check notifications under the alerts section',
                DND_enabled: 'Do Not Disturb mode enabled',
                subscribe_to_cloud: 'Subscribe to cloud storage to save and access your recordings easily',
                subscribe_note: 'Subscribe to Cloud Storage',
                firmware_update_message_1: 'An update is available for your camera firmware.',
                firmware_update_message_2: 'We have added new features and made fixes under the hood!',
                update_firmware_requirement: 'Before you upgrade, ensure your device has a stable WiFi internet connection and is continuously connected to a power source',
                firmware_update_tip: 'The device will be in StandBy mode for the duration of the Firmware Update and will restart at the end.',
                firmware_update_tip2: 'Please make sure you have stable power supply & internet connection.',
                update_success: 'Device firmware successfully updated',
                beta_update_available: "This is a Beta firmware, which means we're in the process of perfecting things for you. Thanks for joining the effort!",
                uptodate_firmware: 'Device is up to date!',
                latest_firmware: "Device up to date! We'll let you know when there's a new update available",
                rollback_inprogress_message: 'Firmware rollback in progress',
                rollback_tip: 'The device will be in StandBy mode for the duration of the Firmware Rollback and will restart at the end.',
                rollback_tip2: 'Please make sure you have stable power supply & internet connection.',
                rollback_success_message: 'Firmware rollback & update successful',
                rollbacked_from_alpha_message: 'Alpha firmware rolled back. Device is now updated with the latest firmware',
                rollbacked_from_beta_message: 'Beta firmware rolled back. Device is now updated with the latest firmware',
                security_mode_information: 'Security mode will disable Sleep and enable Motion detection recordings on all your devices',
                sdcard_format_message: 'Clicking format will permanently erase all contents on your SD card. Please backup all SD card data before proceeding',
                no_motion_notification: "Your ‘Motion Detection’ notifications will appear here",
                no_other_notification: "Your ‘Other’ notifications will appear here",
                no_recording_found: 'No recordings found for selected date. Please try selecting another date',
                recording_deleted: 'Cannot access video. Seems the video has been deleted or video belongs to previous subscription',
                outside_share_window: 'Currently you do not have permission to view this camera',
                share_expired_window: 'Shared camera has expired \n You do not have permission to view this camera'
            }
        }
    },
    fallbackLng: "en",
    debug: true,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
        formatSeparator: ","
    },

    react: {
        wait: true
    }
});

export default i18n;