import React from 'react';
import closeIcon from '../assets/images/close.png';

const PopOverBox = (props) => {
    return (
        <div className="popup-overlay ">
            <div className={"popup-box " + ((props.className !== undefined) ? props.className : '')}>
                {props.hideCloseBtn ? '' : <a href="javascript:" className="closePopup" onClick={props.dismiss}><img src={closeIcon} /></a>}
                {props.children}
            </div>
        </div>
    )
}

export default PopOverBox;