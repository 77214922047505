import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.css';
import '../node_modules/react-widgets/lib/scss/react-widgets.scss';
import './index.css';
import './assets/css/main.css';
import './assets/css/scrubber.css';
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from "firebase/app";

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/analytics";

var firebaseConfig = {
    // Production
    
    apiKey: "AIzaSyABhoZM1Teq-ronCW7ALIAKgS914HTO0vE",
    authDomain: "prod-gss-spotlight.firebaseapp.com",
    projectId: "prod-gss-spotlight",
    storageBucket: "prod-gss-spotlight.appspot.com",
    messagingSenderId: "359451184845",
    appId: "1:359451184845:web:c485f3eefa0f113fa3e384",
    measurementId: "G-BZDRZHW29T"


    // QA and BETA
    // apiKey: "AIzaSyCKp8felGxPAOksv8ahSF9J8W-V2OrUOzY",
    // authDomain: "ehss-421a9.firebaseapp.com",
    // databaseURL: "https://ehss-421a9.firebaseio.com",
    // projectId: "ehss-421a9",
    // storageBucket: "ehss-421a9.appspot.com",
    // messagingSenderId: "549070371679",
    // appId: "1:549070371679:web:9801947b75ac51aca8d4ea",
    // measurementId: "G-EDLPHVM2J2"
};
firebase.initializeApp(firebaseConfig);
firebase.analytics();

ReactDOM.render(
    <I18nextProvider i18n={i18n}>
        <App />
    </I18nextProvider>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();