import axios from 'axios';
import Const from '../common/constants';
import Amplify, { Auth } from 'aws-amplify';
import * as AuthAPI from '../api/auth.api';
import { logout } from '../services/user-store.service';
let instance = axios.create({
    baseURL: Const.API.Endpoint + "/user-management",
    headers: { 'Content-Type': 'application/json' }
});

instance.interceptors.request.use(
    async function(config) {
        let response = await Auth.currentSession(); //1. Make a synchronous API call to refresh token
        let token = response.getIdToken().getJwtToken(); //2. Once successful, set in config.headers.Authorization as Bearer token
        config.headers.Authorization = `Bearer ${token}`;

        return config;
    },
    function(error) {
        window.location.href = '/auth/login';
        return Promise.reject(error);
    }
);


instance.interceptors.response.use(
    response => {
        // console.log(response);
        return response
    },
    (error) => {
        if (error.response !== undefined && error.response.data.statusCode === "dms-401-1008") {
            logout();
        }

        if (error.name !== undefined && error.name === "NotAuthorizedException") {
            logout();
        } else {
            return Promise.reject(error);
        }
    }
)

export default instance;