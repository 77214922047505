import API from '../services/api-config.service';

export function login(logindata) {
    return API({
        method: 'POST',
        url: '/auth/token',
        data: logindata,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    });
}

export function register(payload) {
    return API({
        method: 'POST',
        url: '/api/patients',
        data: JSON.stringify(payload),
    });
}

export function sendOTP(payload) {
    return API({
        method: 'POST',
        url: '/api/users/send-otp',
        data: JSON.stringify(payload),
    });
}

export function verifyOTP(userID, payload) {
    return API({
        method: 'POST',
        url: `/api/users/${userID}/verify-otp`,
        data: JSON.stringify(payload),
    });
}

export function resetPassword(userID, payload) {
    return API({
        method: 'POST',
        url: `/api/users/${userID}/reset-password`,
        data: JSON.stringify(payload),
    });
}

export function getUser(userID) {
    return API({
        method: 'GET',
        url: `/api/users/${userID}`
    });
}


export function revokeRefreshToken(payload) {
    console.log('Revoking Refresh Token');
    return API({
        method: 'POST',
        url: `https://cognito-idp.ap-south-1.amazonaws.com/`,
        data: JSON.stringify(payload)
    });
}