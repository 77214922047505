import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Amplify, { Auth, Hub } from 'aws-amplify';
import awsconfig from './aws-exports';
import Const from './common/constants';
import PopOverBox from './components/PopOver';
// import WithLoader from './components/hoc/withLoader';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import secureStorage from './services/securedStorage';
import adapter from 'webrtc-adapter';
import IdleTimer from 'react-idle-timer';
import { logout } from './services/user-store.service';

import noInternetIcon from './assets/images/no-internet.png';
/*
    ====
    Comment / uncomment the 
    below configuration accordingly 
    to connect to the desired 
    Server. 
    Servers: QA | Stage(beta) | UAT
    ====
*/


// // ==== Config details for DEV ====
// const oauth = {
//   domain: Const.AWS.DevPool.Domain,
//   scope: ["email", "openid", "profile", "phone", "aws.cognito.signin.user.admin"],
//   redirectSignIn: Const.AWS.DEV_Redirects.SignIn,
//   redirectSignOut: Const.AWS.DEV_Redirects.SignOut,
//   responseType: 'code'
// };

// Auth.configure({
//   oauth: oauth,
//   region: 'ap-south-1',
//   userPoolId: Const.AWS.DevPool.PoolId,
//   userPoolWebClientId: Const.AWS.DevPool.WebClientID,
//   storage: secureStorage
// });


// // ==== Config details for QA ====
// const oauth = {
//   domain: Const.AWS.QAPool.Domain,
//   scope: ["email", "openid", "profile", "phone", "aws.cognito.signin.user.admin"],
//   redirectSignIn: Const.AWS.Redirects.SignIn,
//   redirectSignOut: Const.AWS.Redirects.SignOut,
//   responseType: 'code'
// };

// Auth.configure({
//   oauth: oauth,
//   region: 'ap-south-1',
//   userPoolId: Const.AWS.QAPool.PoolId,
//   userPoolWebClientId: Const.AWS.QAPool.WebClientID,
//   storage: secureStorage
// });


// ==== Config changes for UAT ====
// const oauth = {
//   domain: Const.AWS.UATPool.Domain,
//   scope: ["email", "openid", "profile", "phone", "aws.cognito.signin.user.admin"],
//   redirectSignIn: Const.AWS.UAT_Redirects.SignIn,
//   redirectSignOut: Const.AWS.UAT_Redirects.SignOut,
//   responseType: 'code'
// };

// Auth.configure({
//   oauth: oauth,
//   region: 'ap-south-1',
//   userPoolId: Const.AWS.UATPool.PoolId,
//   userPoolWebClientId: Const.AWS.UATPool.WebClientID,
//   storage: secureStorage
// });



// ==== Config details for Staging ====
// const oauth = {
//   domain: Const.AWS.StagingPool.Domain,
//   scope: ["email", "openid", "profile", "phone", "aws.cognito.signin.user.admin"],
//   redirectSignIn: Const.AWS.Staging_Redirects.SignIn,
//   redirectSignOut: Const.AWS.Staging_Redirects.SignOut,
//   responseType: 'code'
// };

// Auth.configure({
//   oauth: oauth,
//   region: 'ap-south-1',
//   userPoolId: Const.AWS.StagingPool.PoolId,
//   userPoolWebClientId: Const.AWS.StagingPool.WebClientID,
//   storage: secureStorage
// });


// // ==== Config details for Production ====
const oauth = {
  domain: Const.AWS.Production.Domain,
  scope: ["email", "openid", "profile", "phone", "aws.cognito.signin.user.admin"],
  redirectSignIn: Const.AWS.Production_Redirects.SignIn,
  redirectSignOut: Const.AWS.Production_Redirects.SignOut,
  responseType: 'code'
};

Auth.configure({
  oauth: oauth,
  region: 'ap-south-1',
  userPoolId: Const.AWS.Production.PoolId,
  userPoolWebClientId: Const.AWS.Production.WebClientID,
  storage: secureStorage
});


const loading = () => <div></div>;
{/* <div className="loading-screen"><div className="loader">Loading...</div></div> */ }

const SignInScreen = React.lazy(() => import('./screens/onboarding/login.screen'));
const IntroPage = React.lazy(() => import('./screens/onboarding/intro.screen'));
const RecoveryPage = React.lazy(() => import('./screens/onboarding/recovery.screen'));
const SocialVerifyScreen = React.lazy(() => import('./screens/onboarding/verify.screen'));
const SuccessPage = React.lazy(() => import('./screens/onboarding/success.screen'));
const WelcomePage = React.lazy(() => import('./screens/welcome.screen'));
const ReadSharedLink = React.lazy(() => import('./screens/read-shared-link'));
const ReadLink = React.lazy(() => import('./screens/readLink'));
const ReadSubscriptionLink = React.lazy(() => import('./screens/subscription-read-link'));
const SingleDevicePage = React.lazy(() => import('./screens/single-device.screen'));
const HomeScreen = React.lazy(() => import('./screens/home.screen'));
const PlaybackScreen = React.lazy(() => import('./screens/playback.screen'));
const ProfileLayout = React.lazy(() => import('./components/ProfileLayout'));
const AlertsLayout = React.lazy(() => import('./components/AlertsLayout'));

const MyProfilePage = React.lazy(() => import('./screens/profile/my-profile'));
const PrivacyPolicy = React.lazy(() => import('./screens/profile/privacy-policy'));
const Termsandconditions = React.lazy(() => import('./screens/profile/terms-and-conditions'));
const CancellationPolicy = React.lazy(() => import('./screens/profile/cancellationPolicy'));
const ChangeMobileNoScreen = React.lazy(() => import('./screens/profile/change-mobile'));
const ChangeEmailScreen = React.lazy(() => import('./screens/profile/change-email'));
const ChangePasswordScreen = React.lazy(() => import('./screens/profile/change-password'));
const ProfileQuickActions = React.lazy(() => import('./screens/profile/quick-actions'));
const CloudSubscriptions = React.lazy(() => import('./screens/profile/subscriptions'));
const ScheduleRecordingProfileForm = React.lazy(() => import('./screens/profile/schedule-recording-form'));
const MotionRecordingProfileForm = React.lazy(() => import('./screens/profile/motion-detection-form'));
const ProfilePlayback = React.lazy(() => import('./components/parts/VideoPlaybacks'));
const FAQPage = React.lazy(() => import('./screens/profile/faq'));

const AlertMotion = React.lazy(() => import('./screens/alerts/motion-alerts'));
const AlertOthers = React.lazy(() => import('./screens/alerts/other-alerts'));

const MyGroupsPage = React.lazy(() => import('./screens/profile/my-groups'));

const DeviceLayout = React.lazy(() => import('./components/device-management/DeviceLayout'));
const DefaultLayout = React.lazy(() => import('./components/device-management/DefaultLayout'));

const MyDevicePage = React.lazy(() => import('./screens/device-management/my-device'));
const ResetDevice = React.lazy(() => import('./screens/device-management/reset-device'));
const SDCardStorage = React.lazy(() => import('./screens/device-management/sdcard'));
const CloudStorage = React.lazy(() => import('./screens/device-management/cloud-storage'));
const FirmwareInfo = React.lazy(() => import('./screens/device-management/firmware'));
const SleepMode = React.lazy(() => import('./screens/device-management/sleep-mode'));
const DoNotDisturb = React.lazy(() => import('./screens/device-management/dnd'));
const SecurityMode = React.lazy(() => import('./screens/device-management/security'));
const MotionDetection = React.lazy(() => import('./screens/device-management/motion-detection'));

const ShareDevice = React.lazy(() => import('./screens/device-management/share-device'));
const ShareDeviceForm = React.lazy(() => import('./screens/profile/share-form'));
const ShareDetails = React.lazy(() => import('./screens/profile/share-details'));
const ShareDeviceLayout = React.lazy(() => import('./screens/device-management/share-form'));

const PlanUpdate = React.lazy(() => import('./screens/profile/subscriptionComponents/planUpdate'));
const PaymentDetails = React.lazy(() => import('./screens/profile/subscriptionComponents/paymentDetails'));
const RedirectToBilldeskScreen = React.lazy(() => import('./screens/profile/subscriptionComponents/redirectScreenBilldesk'));


class App extends Component {

  constructor(props) {
    super(props)

    this.state = {
      timeout: 3600000,  // 1hr in miliseconds
      showModal: false,
      userLoggedIn: false,
      isTimedOut: false,
      isOffline: false
    }

    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this);

    window.addEventListener('online', () => this.setState({ isOffline: false }));
    window.addEventListener('offline', () => this.setState({ isOffline: true }));
  }

  _onAction(e) {
    this.setState({ isTimedOut: false })
  }

  _onActive(e) {
    this.setState({ isTimedOut: false })
  }

  _onIdle(e) {
    const isTimedOut = this.state.isTimedOut
    if (isTimedOut) {
      logout();
    } else {
      this.idleTimer.reset();
      this.setState({ isTimedOut: true })
    }
  }


  render() {
    let { isOffline } = this.state;
    return (
      <div>
        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={this.state.timeout} />
        <BrowserRouter>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route exact path="/auth/login" name="Login Page" render={props => <SignInScreen {...props} />} />
              <Route exact path="/auth/intro" name="Intro Page" render={props => <IntroPage {...props} />} />
              <Route exact path="/auth/verify" name="Verification Page" render={props => <SocialVerifyScreen {...props} />} />
              <Route exact path="/auth/recovery" name="Recovery Page" render={props => <RecoveryPage {...props} />} />
              <Route exact path="/auth/success/:ref" name="Success" render={props => <SuccessPage {...props} />} />
              <Route exact path="/members/welcome" name="Welcome" render={props => <WelcomePage {...props} />} />
              <Route exact path="/devices/:id" name="Welcome" render={props => <SingleDevicePage {...props} />} />
              <Route exact path="/devices/:id/playback" name="Playback" render={props => <PlaybackScreen {...props} />} />
              <Route exact path="/shared/:configId" name="Shared Device" render={props => <ReadSharedLink {...props} />} />
              <Route exact path="/profile/readLink" name="Shared Device" render={props => <ReadSubscriptionLink {...props} />} />
              <Route exact path="/readLink/:type" name="Welcome" render={props => <ReadLink {...props} />} />
              <Route exact path="/redirectToBilldesk" name="Redirecting" render={props => <RedirectToBilldeskScreen {...props} />} />

              <ProfileLayout exact path="/profile/my-profile" name="My Profile" render={props => <MyProfilePage {...props} />} />
              <ProfileLayout exact path="/privacy-policy" name="Privacy Policy" render={props => <PrivacyPolicy {...props} />} />
              <ProfileLayout exact path="/terms" name="Terms and Conditions" render={props => <Termsandconditions {...props} />} />
              <ProfileLayout exact path="/cancellation-policy" name="Cancellation Policy" render={props => <CancellationPolicy {...props} />} />
              <ProfileLayout exact path="/profile/my-profile/change-mobile" name="Change Mobile Number" render={props => <ChangeMobileNoScreen {...props} />} />
              <ProfileLayout exact path="/profile/my-profile/change-email" name="Change Email ID" render={props => <ChangeEmailScreen {...props} />} />
              <ProfileLayout exact path="/profile/my-profile/change-password" name="Change Password" render={props => <ChangePasswordScreen {...props} />} />
              <Route exact path="/profile/my-groups" name="My Groups" render={props => <MyGroupsPage {...props} />} />
              <Route exact path="/profile/subscriptions" name="Cloud Subscription" render={props => <CloudSubscriptions {...props} />} />
              <Route exact path="/profile/faq" name="FAQ" render={props => <FAQPage {...props} />} />
              <Route exact path="/profile/subscription/plan-update" name="Plan Update" render={props => <PlanUpdate {...props} />} />
              <Route exact path="/profile/subscription/paymentDetails" name="Payment Details" render={props => <PaymentDetails {...props} />} />
              <Route exact path="/profile/:id/playback" name="Playback" render={props => <ProfilePlayback {...props} />} />
              <Route exact path="/profile/schedule/:id" name="Schedule Recording Configuration" render={props => <ScheduleRecordingProfileForm {...props} />} />
              <Route exact path="/profile/motion/:id" name="Motion Detection Schedule" render={props => <MotionRecordingProfileForm {...props} />} />

              <Route exact path="/profile/quick-actions" name="Quick Actions" render={props => <ProfileQuickActions {...props} />} />
              <Route exact path="/profile/quick-actions/:what" name="Quick Actions" render={props => <ProfileQuickActions {...props} />} />
              <ProfileLayout exact path="/profile/new-share" name="New Share" render={props => <ShareDeviceForm {...props} />} />
              <ProfileLayout exact path="/profile/edit-share" name="Edit Share" render={props => <ShareDeviceForm {...props} />} />
              <ProfileLayout exact path="/profile/:id/share-details" name="Camera Share" render={props => <ShareDetails {...props} />} />


              <Route exact path="/notifications" name="Motion Detection Alerts" render={props => <AlertMotion {...props} />} />
              <Route exact path="/notifications/others" name="Other Alerts" render={props => <AlertOthers {...props} />} />

              <Route exact path="/devices/:id/manage" name="My Device" render={props => <MyDevicePage {...props} />} />
              <Route exact path="/devices/:id/reset" name="Reset Device" render={props => <ResetDevice {...props} />} />
              <DeviceLayout exact path="/devices/:id/security" name="Security Mode" render={props => <SecurityMode {...props} />} />
              <Route exact path="/devices/:id/sdcard-storage" name="SD Card Storage" render={props => <SDCardStorage {...props} />} />
              <Route exact path="/devices/:id/firmware" name="Firmware Information" render={props => <FirmwareInfo {...props} />} />
              <DeviceLayout exact path="/devices/:id/dnd" name="Do Not Disturb" render={props => <DoNotDisturb {...props} />} />

              <Route exact path="/devices/:id/share" name="Camera Share" render={props => <ShareDevice {...props} />} />
              <Route exact path="/devices/:id/new-share" name="New Share" render={props => <ShareDeviceLayout {...props} />} />
              <Route exact path="/devices/:id/edit-share" name="Edit Share" render={props => <ShareDeviceLayout {...props} />} />
              <Route exact path="/devices/:id/sleep-mode" name="Flip View" render={props => <SleepMode {...props} />} />
              <Route exact path="/devices/:id/cloud-storage" name="Cloud Storage" render={props => <CloudStorage {...props} />} />
              <Route exact path="/devices/:id/motion-detection" name="Motion Detection" render={props => <MotionDetection {...props} />} />
              <Route exact path="/" name="Intro Page" render={props => <IntroPage {...props} />} />
            </Switch>
          </React.Suspense>
        </BrowserRouter>


        {
          (isOffline) ?
            <PopOverBox hideCloseBtn={true}>
              <React.Fragment>
                <div className="text-center">
                  <img src={noInternetIcon} width="210px" className="mb-45 mt-50" />
                  <h3 className="mb-25">Somethings Missing !!</h3>
                  <p className="f-18 mb-45">Looks like Internet is not available, click<br /> Retry to try again.</p>
                  <div className="link-wrapper">
                    <button type="button" className="btn btn-info btn-long">Retry</button>
                  </div>
                </div>
              </React.Fragment>
            </PopOverBox>
            : null
        }
      </div>
    );
  }
}

export default App;
